import React from 'react';
import dp from './dp.png';

function Home() {
  return (
    <div className="content-container">
            <div className="image-container">
              <img src={dp} alt="Pritha"/>
            </div>
            <div className="text-container">
              <div className="large-text">Pritha Ghosh</div>
              <div className="medium-text">Senior Clinical Scientist</div>
              <div className="small-text">ConcertAI, Bangalore</div>
            </div>
            <div className="button-container">
              <a href='https://www.linkedin.com/in/ghosh-pritha/'><button className='outlined-button'>Experience</button></a>
              <a href='https://scholar.google.co.in/citations?user=MWM26BoAAAAJ&hl=en'><button className='outlined-button'>Publications</button></a>
              <a href='https://www.instagram.com/foodledoofle/'><button className='outlined-button'>Gallery</button></a>
            </div>
    </div>
  );
}

export default Home;